<template>
  <div class="success">
    <p class="title">申请成功</p>
    <p class="success-top">
      <img src="./img/success.png" alt="" />
      <span>申请成功</span>
    </p>
    <p class="cont cont2">您的借款申请已提交</p>
    <p class="cont cont1">我们会在2小时内完成审核</p>
    <p class="btn" @click="goto()">{{ data.btnTit }}</p>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import Success from '../../components/Success/index'
import {useRouter} from 'vue-router'

export default {
  components: { Success },
  setup () {
    const Router =useRouter()
    const state = reactive({
      data: { btnTit: '完成' },
      phoneType: null

    })
    const goto = () => {
     // Router.go(-3)

      Router.push('/index')
    }
    return {
      ...toRefs(state),
      goto
    }
  }
}
</script>

<style lang="less">
p {
  margin: 0;
  padding: 0;
}
.title {
  height: 100px;
  text-align: center;
  background-color: #fff;
  width: 100%;
  line-height: 100px;
  font-size: 36px;

  font-weight: 500;
  color: #1e1e1e;
}
.success {
  width: 100%;
  height:100vh;
  background: #f7f6f9;
  display: flex;
  flex-direction: column;
  align-items: center;

  .success-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 122px;

    img {
      width: 196px;
      height: 195px;
      margin-top: 190px;
      margin-bottom: 36px;
    }

    span {
      font-size: 32px;

      font-weight: 500;
      color: #7ed321;
    }
  }

  .cont {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #757575;
    margin: 0 48px 0 43px;
    text-align: center;
    // line-height: 44px;
  }
  .cont1 {
    margin-top: 10px;
    margin-bottom: 106px;
  }

  .btn {
    width: 245px;
    height: 76px;
    border-radius: 38px;
    border: 1px solid #817ff7;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #817ff7;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
